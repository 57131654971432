import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

import * as fromStore from 'src/app/store/';
import { CommentService } from 'src/app/services/comment.service';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-tickets-detail-shell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['detail-shell.component.scss'],
  templateUrl: './detail-shell.component.html',
  animations: [
    trigger('descriptionAnimation', [
      state(
        'expand',
        style({
          height: '*',
          minHeight: '26px'
        })
      ),
      state(
        'collapse',
        style({
          height: '26px'
        })
      ),
      transition('collapse <=> expand', animate('300ms ease-in-out'))
    ]),
    trigger('detailsAnimation', [
      state(
        'expand',
        style({
          height: '*',
          opacity: '1'
        })
      ),
      state(
        'collapse',
        style({
          height: '0',
          opacity: '0'
        })
      ),
      transition('expand <=> collapse', animate('300ms ease-in-out'))
    ])
  ]
})
export class TicketsDetailShellComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() ticket: Ticket;
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Output() reloadTickets: EventEmitter<any> = new EventEmitter();

  ticket$: Observable<Ticket>;
  isTicketIdOnRouter = false;

  ticketSubscription: Subscription;
  commentSubscription: Subscription;
  subscription: Subscription;

  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string;
  allowUserInput = false;
  showDetails = true;
  showDescription = false;
  descriptionState = 'collapse';
  detailState = 'expand';

  descriptionContentHeight = 0;
  descriptionHeight = 0;
  widgetHeight = 0;
  ticketDetailHeaderHeight = 0;

  fileUploadResult = {};

  @ViewChild('descriptionElement', { static: false, read: ElementRef }) private descriptionElement: ElementRef;
  @ViewChild('ticketDetailContent', { static: false, read: ElementRef }) private ticketDetailContent: ElementRef;
  @ViewChild('ticketDetailHeader', { static: false }) ticketDetailHeader: ElementRef;
  @ViewChild('ticketDetailWidget', { static: false, read: ElementRef }) private ticketDetailWidget: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private commentService: CommentService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        if (name !== this.browserSize && this.browserSize === 'small') {
          // you're currently changing from a small device to larger device size
          this.router.navigateByUrl('/tickets');
        }
        this.browserSize = name;
      }
    });
  }

  ngOnChanges() {
    this.initialize();
  }

  ngAfterViewInit() {
    this.getDescriptionIssueHeight();
    this.getTicketHeaderHeight();
    this.getTicketDetailHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
    if (this.commentSubscription) {
      this.commentSubscription.unsubscribe();
    }
  }

  initialize() {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }

    this.isTicketIdOnRouter = !!this.route.snapshot.paramMap.get('ticketId');

    if (this.browserSize === 'small') {
      this.onSmallScreen();
    } else {
      this.onLargeScreen();
    }
    this.fileUploadResult = { name: '', success: false };
    this.checkAllowUserInput();
  }

  onSmallScreen() {
    this.ticket$ = this.store.select(fromStore.getCurrentRouterTicket);
    this.ticketSubscription = this.ticket$.subscribe((ticket) => {
      if (ticket) {
        this.ticket = ticket;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  checkAllowUserInput() {
    if (this.ticket) {
      this.allowUserInput = this.ticket.Business_Purpose__c === 'NOC - Proactive Alert/Customer';
    }
  }

  onLargeScreen() {
    if (this.isTicketIdOnRouter) {
      this.router.navigateByUrl('/tickets');
    }

    this.ticket$ = this.store.select(fromStore.getSelectedTicket);
    this.ticketSubscription = this.ticket$.subscribe((ticket) => {
      if (ticket) {
        this.ticket = ticket;
      }
    });

    this.updateUnreadComments();
  }

  reloadTicketsData() {
    this.reloadTickets.emit();
  }

  fileUploaded(uploadResults) {
    this.fileUploadResult = uploadResults;
  }

  updateUnreadComments() {
    // if ticket has unread comments, change them to read
    if (this.ticket) {
      if (this.ticket.Comments__r && this.ticket.Comments__r.totalSize > 0) {
        if (this.hasUnreadComments(this.ticket.Comments__r.records)) {
          const updateObject = {
            Case__c: this.ticket.Id,
            Is_Read__c: true
          };

          this.commentSubscription = this.commentService.updateCommentsByTicket(updateObject).subscribe();
        }
      }
    }
  }

  hasUnreadComments = (comments) => {
    if (comments) {
      return comments.some((c) => !c.Is_Read__c);
    }
    return false;
  };

  // Get heights for smooth slide/collapse animations
  getTicketHeaderHeight() {
    if (this.ticketDetailHeader && this.ticketDetailHeader.nativeElement) {
      this.ticketDetailHeaderHeight = this.ticketDetailHeader.nativeElement.offsetHeight;
    }
  }

  getTicketDetailHeight() {
    if (this.ticketDetailWidget && this.ticketDetailWidget.nativeElement) {
      this.widgetHeight = this.ticketDetailWidget.nativeElement.offsetHeight;
    }
  }

  getDescriptionIssueHeight() {
    if (this?.descriptionElement) {
      try {
        this.descriptionHeight = this.descriptionElement.nativeElement.offsetHeight;

        if (this.ticketDetailContent && this.ticketDetailContent.nativeElement) {
          this.descriptionContentHeight = this.ticketDetailContent.nativeElement.offsetHeight;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  getDescriptionSections(): string[] {
    if (this.ticket.Description) {
      return this.ticket.Description.split('\n');
    }
    return [];
  }

  // States for animations
  toggleDetailState() {
    this.detailState = this.detailState === 'expand' ? 'collapse' : 'expand';
  }

  toggleDescriptionState() {
    this.descriptionState = this.descriptionState === 'collapse' ? 'expand' : 'collapse';
  }

  animationDone() {
    this.getDescriptionIssueHeight();
    this.getTicketDetailHeight();
  }
}
