import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effects } from 'src/app/store';
/*  combined common modules into one file */
import * as fromGuards from 'src/app/store/guards';
import { SharedModule } from '../shared/shared.module';

// components & containers
import * as fromComponents from './components';
import * as fromContainers from './containers';

// guards

import * as fromServicesReducer from '../store/reducers/services.reducer';
import * as fromTicketsReducer from '../store/reducers/tickets.reducer';
import * as fromContactsReducer from '../store/reducers/contacts.reducer';
import * as fromLineItemsReducer from '../store/reducers/line-items.reducer';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgChartsModule } from 'ng2-charts';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

const ticketRoutes: Routes = [
  {
    path: '',
    component: fromContainers.TicketsShellComponent,
    canActivate: [fromGuards.AuthGuard, fromGuards.TicketsGuard, NgxPermissionsGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    NgChartsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ScrollingModule,
    RouterModule.forChild(ticketRoutes),
    StoreModule.forFeature('contacts', fromContactsReducer.reducer),
    StoreModule.forFeature('services', fromServicesReducer.reducer),
    StoreModule.forFeature('tickets', fromTicketsReducer.reducer),
    StoreModule.forFeature('lineItems', fromLineItemsReducer.reducer),
    EffectsModule.forFeature(effects)
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers, FileUploadComponent],
  providers: [...fromGuards.guards],
  exports: [...fromComponents.components, ...fromContainers.containers]
})
export class TicketModule {}
