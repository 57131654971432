import { NgModule } from '@angular/core';
import { CommonModule, NgFor, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

// custom pipes
import { PositiveNumberPipe } from './custom-pipes/positive-number.pipe';
import { PhoneNumberPipe } from './custom-pipes/phone-number.pipe';
import { SearchFilterPipe } from './custom-pipes/search-filter.pipe';

// components
import * as fromComponents from './components';
import { AccountMenuComponent } from './portal-layout/menu/account-menu/account-menu.component';
import { FooterComponent } from './portal-layout/footer/footer.component';
import { HeaderComponent } from './portal-layout/header/header.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgChartsModule } from 'ng2-charts';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),

    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgFor,
    AsyncPipe,
    MatMenuModule
  ],

  exports: [
    CommonModule,
    RouterModule,

    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,

    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatIconModule,

    AccountMenuComponent,
    FooterComponent,
    HeaderComponent,
    ...fromComponents.components,

    PositiveNumberPipe,
    PhoneNumberPipe,
    SearchFilterPipe
  ],
  declarations: [
    ...fromComponents.components,
    AccountMenuComponent,
    FooterComponent,
    HeaderComponent,
    PositiveNumberPipe,
    PhoneNumberPipe,
    SearchFilterPipe
  ],
  providers: [PositiveNumberPipe, PhoneNumberPipe, SearchFilterPipe]
})
export class SharedModule {}
