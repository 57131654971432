<ng-container *ngIf="!isLoading">
  <section class="ticket-detail ticketpage" *ngIf="ticket; else noTicket">
    <div class="content">
      <div class="back-heading" *ngIf="browserSize === 'small'">
        <a class="back-link icon-text" [routerLink]="['/tickets']">
          <div class="custom-icon arrow_back"></div>
          Back to Tickets
        </a>
      </div>
      <div class="ticket-detail-content ticket-title-wrapper" #ticketDetailContent>
        <!-- Case Number, Title, Date Created/Closed -->
        <ticket-detail-header [ticket]="ticket" class="ticket-detail-header" #ticketDetailHeader></ticket-detail-header>
      </div>

      <div class="scrolling-list" id="ticketDetailScroll">
        <div class="ticket-detail-content" #ticketDetailContent>
          <!-- Locaion & Service Info -->
          <ticket-detail-service [ticket]="ticket"></ticket-detail-service>

          <div class="ticket-detail-section description-contact">
            <div class="bookends">
              <div class="icon-text section-title">
                <div class="custom-icon ticket">ticket</div>
                <span>Details</span>
              </div>

              <input id="collapsible1" class="toggle" type="checkbox" checked [(ngModel)]="showDetails" />
              <ng-container>
                <label for="collapsible1" class="lbl-toggle" tabindex="0" (click)="toggleDetailState()">
                  <span class="more icon-text" *ngIf="showDetails"
                    >View Less
                    <div class="custom-icon arrow_collapse">arrow_collapse</div>
                  </span>
                  <span class="less icon-text" *ngIf="!showDetails"
                    >View More
                    <div class="custom-icon arrow_expand">arrow_expand</div>
                  </span>
                </label>
              </ng-container>
            </div>

            <!-- Description -->
            <div
              [@detailsAnimation]="detailState"
              (@detailsAnimation.done)="animationDone()"
              *ngIf="ticket.Description || ticket.Contact"
            >
              <div class="description" *ngIf="ticket.Description">
                <div class="description-header">
                  <div class="label">Description of Issue</div>

                  <input id="collapsible2" class="toggle" type="checkbox" [(ngModel)]="showDescription" />
                  <ng-container *ngIf="ticket.Description">
                    <label for="collapsible2" class="lbl-toggle" tabindex="0" (click)="toggleDescriptionState()">
                      <span class="less description-icon-text" *ngIf="showDescription"
                        >View Less
                        <div class="custom-icon arrow_collapse">arrow_collapse</div>
                      </span>
                      <span class="more description-icon-text" *ngIf="!showDescription"
                        >View More
                        <div class="custom-icon arrow_expand">arrow_expand</div>
                      </span>
                    </label>
                  </ng-container>
                </div>

                <div class="container" [@descriptionAnimation]="descriptionState">
                  <div #descriptionElement>
                    <p *ngFor="let section of getDescriptionSections()" [innerHTML]="section + '<br />'"></p>
                  </div>
                </div>
              </div>

              <div class="description-divider" *ngIf="ticket.Description"></div>

              <!-- Primary Reporter -->
              <ticket-detail-contact [ticket]="ticket$ | async" *ngIf="ticket.Contact"></ticket-detail-contact>
            </div>
          </div>

          <!-- File Uploads -->
          <div class="ticket-detail-section">
            <div class="bookends">
              <div class="icon-text section-title">
                <div class="custom-icon file-upload-icon">file upload</div>
                <span>Files</span>
              </div>
            </div>
            <app-file-upload
              [ticket]="ticket"
              [allowUserInput]="allowUserInput"
              (filesUploaded)="fileUploaded($event)"
            ></app-file-upload>
          </div>

          <!-- Comments Header (for large screens only) -->
          <div class="ticket-detail-section slide-comment" *ngIf="browserSize !== 'small'">
            <div class="bookends">
              <div class="icon-text section-title">
                <div class="custom-icon comment">Comment</div>
                <span>Comments ({{ ticket.Comments__r ? ticket.Comments__r.totalSize : '0' }})</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Comments -->
        <div [ngClass]="{ 'comments-collapsed': !showDetails }" *ngIf="browserSize !== 'small'">
          <div class="comments-inner">
            <app-ticket-comment
              [ticket]="ticket"
              [viewMoreComments]="showDetails"
              [widgetHeight]="widgetHeight"
              [fileUploadResult]="fileUploadResult"
              [descriptionContentHeight]="descriptionContentHeight"
              [allowUserInput]="allowUserInput"
              (reloadTicketData)="reloadTicketsData()"
            ></app-ticket-comment>
          </div>
        </div>

        <!-- Comments Header (for small or mobile screens only) -->
        <div class="ticket-detail-section">
          <div class="icon-text section-title comment-title" *ngIf="browserSize === 'small'">
            <span>View Comments</span>
            <div class="comment-total">
              <div class="icon-text comment-count">
                <div *ngIf="!hasUnreadComments(ticket.Comments__r?.records)" class="custom-icon comment_read"></div>
                <div *ngIf="hasUnreadComments(ticket.Comments__r?.records)" class="custom-icon comment_alert"></div>
              </div>
              &nbsp;
              {{ ticket.Comments__r ? ticket.Comments__r.totalSize : '0' }}
              <div class="custom-icon arrow_progress">arrow_progress</div>
            </div>
            <a [routerLink]="['/tickets', ticket.Id, 'comments']" tabindex="-1" class="ticket-link desktop"
              ><div class="cta-link">desktop</div></a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <ng-template #noTicket>
    <section class="widget ticket-detail empty">
      <div class="ticket-detail-header">
        <div class="number-type">
          <div class="number">
            <div class="label">Ticket #</div>
            - - -
          </div>
          <div class="type">
            <div class="label">Ticket Type</div>
            <div class="text">- - -</div>
          </div>
        </div>
        <div class="status-date">
          <div class="label">Status</div>
          - - -
        </div>
      </div>
      <div class="empty-description">
        <h1 class="custom-h1">No tickets have been created for this account at this time.</h1>
        <h1 class="custom-h1">You can add a new ticket from any page by selecting the "Create Ticket" button.</h1>
      </div>
    </section>
  </ng-template>

</ng-container>
